import React, {useRef, useState} from "react";
import "./css/logo.css"
import logo from "./logo.svg"
import team from "./team.svg"
import targeting from "./targeting.svg"
import email from "./email_white_24dp.svg"
import likedinIcon from './linkedin.svg'
import button from "./button.svg"
import button2 from "./button2.png"
import logo5 from "./logo05.png"
import { Typewriter} from 'react-simple-typewriter'
import brochure from "./brochure LIF2022.pdf";

function App() {

    let styles = {
        header: {
            webkitUserSelect: 'none', /* Safari */
            mozUserSelect: 'none', /* Firefox */
            msUserSelect: 'none', /* IE10+/Edge */
            userSelect: 'none', /* Standard */
            position: 'Fixed',
            top: 0,
            left: '0%',
            right: '0%',
            backgroundImage: 'radial-gradient(ellipse at bottom, #4c7abefb, #4e2491fb)',
            color: '#eeeeee',
            height: '56px',
            paddingTop: 15,
            fontFamily: '\'Barlow\', Cursive, serif',
            fontSize: '45px',
            boxShadow: '0px 3px 3px 0px #22222266',
            borderRadius: '0px 0px 1.5px 1.5px',
            zIndex: 10,
            overflow: 'hidden',
        },
        copyright: {
            webkitUserSelect: 'none', /* Safari */
            mozUserSelect: 'none', /* Firefox */
            msUserSelect: 'none', /* IE10+/Edge */
            userSelect: 'none', /* Standard */
            width:'100%',
            position: 'absolute',
            backgroundImage: 'radial-gradient(ellipse at bottom, #4c7abefb, #4e2491fb)',
            color: '#eeeeee',
            height: '56px',
            paddingTop: 15,
            fontFamily: '\'Barlow\', Cursive, serif',
            fontSize: '45px',
            boxShadow: '0px 3px 3px 0px #22222266',
            borderRadius: '0px 0px 1.5px 1.5px',
            zIndex: 3,
            overflow: 'visible',
        },
        rotate: {
            position: 'absolute',
            zIndex: '100',
            top: '67px',
            left: '76px',
            height: '55px',
            margin:'-60px 0 0 -60px',
            transition: 'transform 1s',
            transform: 'rotate(360deg)'
        },
        null: {
            position: 'absolute',
            zIndex: '100',
            top: '67px',
            left: '76px',
            height: '55px',
            margin:'-60px 0 0 -60px',
            transition: 'transform 1s',
            transform: 'rotate(0deg)'
        },
        banner:{
            width: '80%',
            marginLeft: '5%',
            transition: 'transform 0.5s',
            transform: 'scale(1)'
        },
        bannerHover:{
            marginLeft: '5%', width: '80%',
            transition: 'transform 0.5s',
            transform: 'scale(1.2)'
        },
        button: {
            transition: 'transform .4s',
            transform: 'scale(0.9)'
        },
        buttonClick: {
            transition: 'transform .4s',
            transform: 'scale(1)'
        }
    }


    const [anim, setAnim] = useState(styles.null)
    const [rotate, setRotate] = useState(false);
    const [click, setClick] = useState('button')
    const myRef = useRef(null)
    function executeScroll(ref){
        ref.current.scrollIntoView({ behavior: "smooth" });
    }

    const Mailto = ({ email, subject, body, children }) => {
        return (
            <a href={`mailto:${email}?subject=${encodeURIComponent(subject) || ''}&body=${encodeURIComponent(body) || ''}`}>{children}</a>
        );
    };

    function Rotate() {
        if (!rotate)
            setAnim(styles.rotate)
        if (rotate)
            setAnim(styles.null)
        setRotate(!rotate)
    }

    const handleType = (count) => {
        // access word count number
        console.log(count)}

    const handleDone = () => {
        console.log(`Done after 5 loops!`)
    }

    function onBClick(){
        // document.getElementById('contact').scrollIntoView();
        setClick('buttonClick')
        if (myRef && myRef.current)
            executeScroll(myRef)
    }

    function onAnimEnd() {
        setClick('button')
    }

    return(
        <>
            <header style={styles.header}>
                <img className={'logo'} style={anim} src={logo} alt={"logo"} onMouseEnter={Rotate} onMouseLeave={Rotate}/>
                <img onClick={onBClick} onMouseLeave={onAnimEnd} id={'img'} className={click} src={button} alt={"Contact-us"}/>
                <h6 style={{color: 'white'}}>
                    Contact us
                </h6>
            </header>

            <body className={'body'}>

                <div className={'banner'}>
                    <img className={'banner'} alt={"ok"} src={logo5}/>
                </div>

                {/* 
                
                <div className={'card'}
                     style={{
                         padding: 0,
                         backgroundImage: 'radial-gradient(ellipse at top, #4c7abefa, #4E2491fa)',
                         color: '#4E2491',zIndex: 0,
                     }}
                >
                    <div style={{position: 'sticky', zIndex: 4, fontSize: '120%', paddingTop: '20px', color: '#FFFFFF'}}>
                        <text >
                            Meet us!
                        </text>
                        <div className={'column'} style={{width: '100%', padding: 0, paddingBottom: '2.5%'}}>
                            <text>
                                <div style={{fontSize: '80%', color: '#FFFFFF', width: '80%', margin: '0 auto'}}>
                                    Thanks to the initiative "Start-up & Scale-up Innovation 2021"
                                    we will be present at
                                    <a href="https://www.ecomondo.com/settori/progetti-speciali/start-up"> Ecomondo </a>
                                    2021, held in Rimini from 26th until
                                    the 29th of October, come visit us at the stand 29!
                                </div>
                            </text>
                        </div>
                    </div>
                </div> {/* Ecomondo Banner*/}

                <div className={'card'}
                     style={{
                         height: '100%'}}
                >
                    <div className={'row'}>
                        <text style={{fontSize: '250%'}}>
                            {/*<br/>*/}
                            About us
                        </text> {/* title */}
                        <div className={'column'} style={{width: '100%'}}>
                            <text>
                                <div className={'test'} style={{textAlign: 'center'}}>
                                    LIF Energy SRL is an Innovative Startup devoted to the development,
                                    production & commercialization of modelling & analysis tools (at component & system level)
                                    to evaluate the impact of green hydrogen technologies at all stages of the value chain.
                                    Our value proposition is based on advanced human capital applying
                                    science and technology at the service of industry for the energy transition.
                                </div>
                            </text>
                        </div>
                    </div>

                    <text style={{fontSize: '250%', padding: 0}}>
                        Our proposal
                    </text> {/* title */}
                    <div className={'row'} style={{textSize: '50px', textAlign: 'center'}}>
                        
                        <div style={{justifySelf: 'center'}}>
                            Technical and economic consultancy services
                        </div>
                        <img style={{paddingTop: '25px', width: '10%', justifySelf: 'center'}} alt={"ok"} src={targeting}/>
                        <div style={{justifySelf: 'center', width: '90%'}}>
                            <ul style={{textAlign: 'center', margin: '0 auto', width: 'fit-content'}}>
                                <li>Techno-economic analysis </li>
                                <li>Process & system simulation </li>
                                <li>Ownership engineering </li>
                                <li>Consultancy for large-scale demo projects </li>
                                <li>Development of analytical tools to assess green H2 projects </li>
                                <li>Design optimization software for green H2 plants </li>
                            </ul>
                        </div>
                        
                        {/*
                        <div className={'column'} style={{justifyContent: 'center'}} >
                            <div style={{justifySelf: 'center'}}>
                                Development of dedicated tools/software <br/>
                            </div>
                            <img style={{paddingTop: '25px', width: '10%', justifySelf: 'center'}} alt={"ok"} src={team}/>
                            <div style={{justifySelf: 'center', width: '70%'}}>
                                
                            </div>
                        </div>
                        */}
                    </div>

                    <div style={{
                        paddingTop: '5%',
                        background: '#4E2491fb',
                        webkitBackgroundClip: 'text',
                        webkitTextFillColor: 'transparent',
                        zIndex: '3',
                        fontSize: '200%',
                    }}>
                    
                        <Typewriter
                            words={['Clean tech, made simple!']}
                            loop={1}
                            typeSpeed={150}
                            deleteSpeed={50}
                            delaySpeed={1000}
                            onLoopDone={handleDone}
                            onType={handleType}
                        />
                    </div>

                    <div style={{paddingTop: '5%'}}>
                        <text style={{fontSize: '250%', padding: '5%'}}>
                            What we do<br/>
                        </text>{/* title */}
                    </div>
                    <div className={'column2'} style={{textAlign: 'center'}}>
                        Technical & economic feasibility studies and due-diligence <br/>
                        <br/>
                        LIF energy answers to technical and economic questions, levelizing asymmetry of information in the sector <br/>
                        <br/>
                    </div>
                    <div className={'test'}>
                        <ul>
                            <li>What H2 production technology is more suitable for my project?</li><br/>
                            <li>What LCOH can I achieve with the the costs I have quoted with OEMs</li><br/>
                            <li>What configuration of energy supply and/or PPA maximizes the competitiveness of my project?</li><br/>
                            <li>What will be the water consumption related to my project?</li><br/>
                        </ul>
                    </div>
                    <div style={{ width: '80%', textAlign: 'center', margin: '0 auto'}}>
                        In LIF energy we work in each project as if it were ours, with the objective of creating
                        value for our clients by identifying sustainable development opportunities in the green energy & hydrogen transition context.
                    </div>
                    {/*</div> /!* what we do *!/*/}
                </div>
                
                <Mailto email="info@energylif.com" subject="Contact Us" body="">
                    <div ref={myRef} className={'card'} style={{background: 'white', color: 'white'}}>
                            <img style={{width: '35%'}} alt={"bene"} src={button2}/>
                    </div> 
                </Mailto>
                
                
                
                
                
                <br/>

                <footer className={'footer'}>

                {/*
                <center>
                <a href={brochure} target="_blank"
                    rel="noreferrer">
                    Download our brochure
                </a> <br></br>
                </center>
                */}

                    <div>
                        <div className="copyright">Copyright 2024 | Energy LIF</div>
                    </div>
                    <div className={'info'} style={{color: 'white', textAlign: 'right'}}>
                    
                        <div style={{margin: '5px 0'}}>
                            <Mailto email="info@energylif.com" subject="Contact Us" body="">
                                <img style={{display: 'inline-block'}} alt={'email'} src={email}/>
                            </Mailto>
                            <a href={"https://www.linkedin.com/company/77194307/"}>
                                <img style={{display: 'inline-block'}} alt={'likedin'} src={likedinIcon}/>
                            </a>
                        </div>
                        <Mailto email="info@energylif.com" subject="Contact Us" body="">
                            <div style={{transform: 'translateY(-7px)', marginBottom: 10}}>
                                info@energylif.com
                            </div>
                        </Mailto>
                    </div>
                </footer>

            </body>
        </>
    );

}

export default App;